<template>
  <div :class="$style.root">
    <div :class="$style.title">
      <layout-animated-underline-component
        :to="{ name: 'editions-slug-index-urban-vision', params: { slug: biennialEntry?.slug } }"
        :class="$style.link"
        :style="cssBackgroundColorTextDecoration"
        class="font-size-medium"
      >
        <template #default>
          {{ biennialEntry?.title }}
        </template>
      </layout-animated-underline-component>
    </div>

    <core-text-component
      v-if="firstParagraph"
      :string="firstParagraph"
      :class="$style.firstParagraph"
    />

    <layout-animated-underline-component
      :to="{ name: 'editions-slug-index-urban-vision', params: { slug: biennialEntry?.slug } }"
      :class="$style.link"
      :style="cssBackgroundColorTextDecoration"
      :inverted="true"
    >
      <template #default>
        {{ $t('shared.more') }}
      </template>
    </layout-animated-underline-component>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  data: {
    type: Object
  }
})

const biennialEntry = computed(() => props?.data?.biennialEntry)

const colors = computed(() => biennialEntry?.value?.colors)
const cssBackgroundColorTextDecoration = getCssBackgroundColorTextDecoration(colors?.value)

const firstParagraph = computed(() => {
  return baseStrapiGetFirstParagraphFromBlocks(props?.data?.blocks, 40)
})
</script>

<style module>
.root {
  composes: font-size-small from global;
}
</style>
